import {sort_alphabetical_asc, time_sort_asc} from "./SortUtils"
import moment from 'moment';

const letterList = ["A", "B", "C", "D", "E", "F", "G", "H",
                    "I", "J", "K", "L", "M", "N", "O", "P", 
                    "Q", "R", "S", "T", "U", "V", "W", "X", 
                    "Y", "Z"
                  ]

const componentBasicName = "Comp_"

function getComponentsFromLocalStorage(){
    var storageKeys = Object.keys(localStorage);
    
    let componentsList = [];
    storageKeys.forEach(key => {
        if (key.includes(componentBasicName)){
            componentsList.push(key)
        }
    });
    if(componentsList.length === 0){
        return null;
    }
    else{
       return componentsList 
    }
}

function getOpenComponents(){
    var storageKeys = Object.keys(localStorage);
    
    let openComponents = [];
    storageKeys.forEach(key => {
        if (key.includes(componentBasicName) && !localStorage.getItem(key).includes(",")){
            openComponents.push(key)
        }
    });

    return openComponents;
}

function getClosedComponents(){
    var storageKeys = Object.keys(localStorage);
    
    let closedComponents = [];
    storageKeys.forEach(key => {
        if (key.includes(componentBasicName) && localStorage.getItem(key).includes(",")){
            closedComponents.push(key)
        }
    });
    return closedComponents;    
}

function getNewComponentNameInLocalStorage(){
    const compList = getComponentsFromLocalStorage();
    
    let possibleLettersForNewComponent = [];
    if(compList !== null){
        const componentLetterList = compList.map( elem => {
            return elem.substring(5)
        });
    
        letterList.forEach( letter => {
            if(!componentLetterList.includes(letter)){
                possibleLettersForNewComponent.push(letter)
            }
        });
    
        possibleLettersForNewComponent = possibleLettersForNewComponent.sort(sort_alphabetical_asc);
    }
    else{
        possibleLettersForNewComponent = letterList;
    }
    
    return componentBasicName + possibleLettersForNewComponent[0];
}

function updateComponentEntryInLocalStorage(componentName, closed){
    const winLocation = window.location.toString();
    if(closed){
        localStorage.setItem(componentName, winLocation + ", " + moment().format("YYYY-MM-DD HH:mm:ss.SSS")); // .format("x")
    }else{
        localStorage.setItem(componentName, winLocation);
    }
}

function deleteComponentEntryInLocalStorage(componentName){
    localStorage.removeItem(componentName);
}

function removeClosedComponentsFromStorage(componentList){
    for(var i=0; i < componentList.length; i++){
        deleteComponentEntryInLocalStorage(componentList[i])
    }
}

function getLatestClosingTime(componentList){
    var closingTimes = [];
    componentList.forEach( comp =>{
        closingTimes.push(localStorage.getItem(comp).substring(localStorage.getItem(comp).indexOf(",")+2));
    })

    const sortedTimes = closingTimes.sort(time_sort_asc)
    return sortedTimes[sortedTimes.length-1];
}

export{
    getComponentsFromLocalStorage,
    getOpenComponents,
    getClosedComponents,
    getNewComponentNameInLocalStorage,
    updateComponentEntryInLocalStorage,
    deleteComponentEntryInLocalStorage,
    removeClosedComponentsFromStorage,
    getLatestClosingTime
}