import React from 'react';
import {Trans} from 'react-i18next';

function fadingSpinner(){
    return(
        <div>
            <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
            </div>
        </div>
    );
}

function loading(){
    return(
        <div className="animated fadeIn pt-3 text-center">
            <Trans i18nKey="common:load"/>
        </div>
    );
}


/*****************************************************************
USAGE:
    sleep(500).then(() => {
        // Do something after the sleep!
    })
******************************************************************/
function sleep(milliseconds){
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export {fadingSpinner, loading, sleep};